.filter-bar {
  background-color: #282c34;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1% 1%;
}

.mantine-Select-label {
  color: white;
}

.mantine-TextInput-label {
  color: white;
}

.eat-button {
  display: inline-block;
  align-self: flex-end;
}

form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, max-content));
  width: 90vw;
  grid-gap: 1px;
  align-content: center;
  justify-content: space-around;
}
