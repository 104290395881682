.restaurant-list {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  padding: 1% 1%;
  border-radius: 25px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
}
