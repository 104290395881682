.restaurant-tile {
  border: 1px;
  border-color: #7d9c88;
  border-style: solid;
  display: flex;
  flex-direction: column;
  padding: 2% 2%;
  border-radius: 25px;
  margin: 10px 0px;
}

.tile {
  display: flex;
  padding: 2px 0%;
  align-items: center;
  flex-wrap: nowrap;
}
