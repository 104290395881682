*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

main {
  max-width: 1240px;
  padding: 16px;
  margin: auto;
  margin-top: 86px;
  height: 100%;
}

.App {
  color: white;
  background-color: #282c34;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue';
  height: 100%;
  width: 100vw;
}

.app-header {
  height: 15vh;
  width: 100vw;
  margin-bottom: 5px;
  background-color: #282c34;
}

.app-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #282c34;
}

.button {
  background-color: #7d9c88;
}

.button:hover {
  background-color: #7d9c88;
  filter: brightness(80%);
}
