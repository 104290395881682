.top-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  height: 100px;
  background-color: #282c34;
  border: 1px;
  border-color: #7d9c88;
  border-style: solid;
  font-weight: bold;
  background-color: #7d9c88;
}

.nav-button {
  height: 100%;
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.logo {
  width: 60px;
}

#logo {
  justify-content: flex-start;
}
